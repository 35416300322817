<template>
  <b-card v-if="mySession.role">
      <b-tabs align="left" class="tab-primary" v-model="tabIndex">
        <!-- Etiquetas -->
        <b-tab lazy>
          <template #title>
            <div>
              <feather-icon icon="FileTextIcon" />
              <span> {{$t('Etiquetas')}} </span>
            </div>
          </template>
          <printer-labels></printer-labels>
        </b-tab>
        <!-- Manifiesto -->
        <b-tab lazy>
          <template #title>
            <div>
              <feather-icon icon="ArchiveIcon" />
              <span> {{$t('Manifiesto')}} </span>
            </div>
          </template>
          <list-summary></list-summary>
        </b-tab>
        <!-- Impresos -->
        <b-tab lazy>
          <template #title>
            <div>
              <feather-icon icon="FileTextIcon" />
              <span> {{$t('Impresos')}} </span>
            </div>
          </template>
          <list-printed/>
        </b-tab>
        <!-- Con observacion -->
        <b-tab lazy>
          <template #title>
            <div>
              <feather-icon icon="AlertTriangleIcon" />
              <span> {{$t('Con observación')}} </span>
            </div>
          </template>
          <failed-deliveries></failed-deliveries>
        </b-tab>
        <!-- Listado  -->
        <b-tab lazy>
          <template #title>
            <div class="tab-title">
              <feather-icon icon="ListIcon" />
              <span> {{$t('Listado')}} </span>
            </div>
          </template>
          <success-deliveries></success-deliveries>
        </b-tab>
      </b-tabs>
    </b-card>
</template>

<script>
import ListSummary from '../labels/ListSummary.vue'
import PrinterLabels from '../labels/PrinterLabels.vue'
import FailedDeliveries from './FailedDeliveries.vue'
import SuccessDeliveries from './SuccessDeliveries.vue'
import ListPrinted from '../labels/ListPrinted.vue'
import { mapGetters } from 'vuex'
export default {
  components: { SuccessDeliveries, FailedDeliveries, PrinterLabels, ListSummary, ListPrinted },
  data() {
    return {
      tabIndex: 0
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession'
    })
  }
}
</script>

<style>

</style>