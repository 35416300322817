<template>
  <div>
    <filter-swapper v-if="fields.length !== 0" :buttons="buttons" :controlHeightButtons="controlHeight">
        <template #slot1>
        <form-render :form.sync="form" :key="keyFormRender" :fields="fields" @send="filterList" :buttonSend="buttonSend" ref="formFilter"
            containerButtonsClass="col-md-2 container-button mt-2" class="mb-2">
            <template #buttons>
            <b-button variant="outline-light" v-b-tooltip.hover title="Limpiar filtros" class="ml-1" @click="cleanFilter"><feather-icon icon="RefreshCwIcon"/></b-button>
            </template>
        </form-render>
        </template>
    </filter-swapper>
    <div v-if="loading.first && rows.length === 0" class="d-flex flex-column justify-content-center align-items-center" style="height: 470px">
        <div class="col-lg-6">
        <div class="text-center">
            <feather-icon class="text-center" icon="FileTextIcon" size="52" style="color:#B9B9C7"/>
        </div>
        <div class="text-wrap text-center my-1" style="font-size:14px;color:#B9B9C7">
            Utiliza los selectores disponibles en la parte superior
            para realizar una búsqueda eficiente y acceder a la información deseada.
        </div>
        </div>
    </div>
    <div v-show="!loading.first">
      <form-render class="px-0 mt-3 mb-2" :fields="fieldsType" :form.sync="selectedRadioOption"></form-render>
      <div>
        <div class="row mb-2">
          <div class="col-12 col-md-6">            
            <!-- Reimprimir (Con manifiesto) -->
            <span v-b-tooltip="disabledPanel ? $t('msg-alert-disabled-panel') : ''">
              <b-button v-if="selectedCheck === 1" :disabled="disabledPanel" @click="showLabelsPrintOptions('only-print')" variant="outline-light" class=""><feather-icon icon="PrinterIcon"/> Reimprimir</b-button> 
            </span>
            <!-- Dropdown opstions (Para sin manifiesto)-->
            <b-dropdown v-if="selectedCheck === 2" class="" variant="outline-light">
              <template #button-content>
                <feather-icon icon="ListIcon"/> Más opciones
              </template>
              <!-- Reimprimir (Sin manifiesto) -->
              <b-dropdown-item v-b-tooltip="disabledPanel ? $t('msg-alert-disabled-panel-reprint') : ''" :disabled="disabledPanel" @click="showLabelsPrintOptions('only-print')"> <feather-icon icon="TagIcon"/> {{ $t('Reimprimir etiqueta') }} </b-dropdown-item>
              <b-dropdown-item v-b-tooltip="disabledPanel ? $t('msg-alert-disabled-panel-assign') : ''" :disabled="disabledPanel" @click="showLabelsPrintOptions('only-assign')"> <feather-icon icon="FileIcon"/> {{ $t('Asignar a manifiesto') }} </b-dropdown-item>
              <b-dropdown-item v-b-tooltip="disabledPanel ? $t('msg-alert-disabled-panel') : ''" :disabled="disabledPanel" @click="showLabelsPrintOptions('assign-print')"> <feather-icon icon="PrinterIcon"/> {{ $t('Reimprimir y asignar a manifiesto') }} </b-dropdown-item>
            </b-dropdown> 
            <span class="ml-1"> <strong>{{selectedRows.length}} envíos seleccionados</strong> </span>
          </div>
          <div class="col-12 col-md-6 mt-1 mt-md-0">
            <b-button @click="downloadExcel" :disabled="loading.excel" class="push-right" variant="outline-success">
              <feather-icon v-if="!loading.excel" icon="DownloadIcon"/> <i v-else :class="['fa', 'fa-spinner', 'fa-spin']"/>  {{$t('Exportar a excel')}} 
            </b-button>
          </div>
        </div>
      </div>
      <div class="table-listprinted">
          <table-render
            :schema.sync="schema"
            :rows="rows"
            :actions="actions"
            :loading="loading.printed"
            :stickyHeader="`calc(100vh - 2.5rem)`"
            id="listprinted"
            :tableClass="'with-scroll'"
            :striped="true"
            :showCheckboxes="true"
            :selectedRows.sync="selectedRows"
            >
          <template #status_name="scope">
            <span :class="`table_dot--state ${$options.filters.getStatusClasses()[getStatusPrintedId(scope.rowdata.status)]}`"></span>&nbsp; {{scope.rowdata.status_name}}
          </template>
          <template #delivery_id="scope">
            <a
            class="underline-text"
            :href="openDeliveryDetails(scope.rowdata.id)"
            target="_blank"
            rel="noopener noreferrer"
          >
            {{ scope.rowdata.delivery_id }}
          </a>
          </template>
          <template #client_name="scope">
            <b-button
              v-b-tooltip.hover.html="$generateTooltipPerson(scope.rowdata.customer)" 
              variant="link" 
            >
              {{scope.rowdata.customer.name}}
            </b-button>
          </template>
          </table-render>
          <pagination :pagination="pagination" :noDigits="false" :showSize="true" :disabled="loading.printed"/>
      </div>
    </div>
      <modal-delivery-labels :deliveriesIds="selectedRows" :serviceType="serviceType" @sendLabelResponse="collectLabelsPrintData" :refreshTable="true"></modal-delivery-labels>
      <modal-delivery-labels-print :orderedLabels="orderedLabels" ></modal-delivery-labels-print>
      <modal-assign-summary :deliveriesIds="selectedRows" :processType="serviceType" :refreshTable="true" @setTable="setTable"></modal-assign-summary>
  </div>
</template>
<script>
import ModalDeliveryLabels from '@/views/modules/deliveries/create/printed/ModalDeliveryLabels.vue'
import ModalDeliveryLabelsPrint from '@/views/modules/deliveries/create/printed/ModalDeliveryLabelsPrint.vue'
import ModalAssignSummary from '@/views/modules/deliveries/create/printed/ModalAssignSummary.vue'
import { mapGetters } from 'vuex'
import BaseServices from '@/store/services/index'
export default {
  components: {ModalDeliveryLabels, ModalDeliveryLabelsPrint, ModalAssignSummary},
  data () {
    return {
      controlHeight: { class: 'row mb-2 spacing-label-field'},
      buttonSend: {title: 'Buscar', icon: 'SearchIcon', color: 'warning'},
      buttons: [],
      keyFormRender: 0,
      fields: [],
      form: {},
      schema: [],
      rows: [],
      actions: [],
      pagination: {
        page: 1,
        total: 0,
        limit: 20
      },
      loading: {
        printed: false,
        first: true,
        excel: false
      },
      listCount: {
        withSummaries: 0,
        noSummaries: 0
      },
      selectedRadioOption: {},
      fieldsType: [],
      selectedCheck: 1,
      disabledPanel: true,
      selectedRows: [],
      optionState: [],
      filters: {},
      ROLES : {
        marketplace: 'marketplace',
        ecommerce: 'ecommerce',
        seller: 'seller',
        admin: 'admin'
      },
      baseService: new BaseServices(this),
      withSummaries: true,
      setPrinted: true,
      serviceType: null,
      orderedLabels: []
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession',
      organizations: 'getOrganizations',
      shippersByOrganization: 'getShippersByOrganizationSimplifiedQueryParams',
      printed: 'getListPrinted',
      generalLoading: 'getLoading',
      marketcenters: 'getMarketcenterByShipperId'
    })
  },
  watch: {
    printed(value) {
      this.pagination.total = value.total ?? 0
      const {deliveriesSummary, deliveriesNotSummary} = this.setRows()
      this.optionState[0].html = `Con Manifiesto <span class="badge badge-primary badge-pill">${deliveriesSummary.length}</span>`
      this.optionState[1].html = `Sin Manifiesto <span class="badge badge-warning badge-pill">${deliveriesNotSummary.length}</span>`
      this.loading.first = false
    },
    mySession() {
      if (this.mySession.id) this.setInitialData()
    },
    selectedRows () {
      if (this.selectedRows?.length === 0) this.disabledPanel = true
      else this.disabledPanel = false
    },
    marketcenters () {
      const options = this.marketcenters?.map(({code, name}) => ({id: code, text: name}))
      this.setSelectOptions('integration', { options })
    },
    shippersByOrganization () {
      this.setSelectOptions('shipper_id', { options: this.shippersByOrganization })
    },
    organizations () {
      this.setSelectOptions('organization_id', { options: this.organizations.rows })
    },
    generalLoading: {
      handler () {
        this.loading.printed = !!this.generalLoading.getListPrinted
      },
      deep: true
    },
    'pagination.page' () {
      this.filterList()
    },
    'pagination.limit' () {
      if (this.pagination.page === 1) this.filterList()
    }
  },
  mounted () {
    this.setInitialData()
  },
  methods: {
    setInitialData () {
      this.setFields()
      this.setFieldsType()
      this.setSchema()
    },
    setFields () {
      this.fields = [
        { fieldType: 'FieldSelect', containerClass: 'col-md-3', name: 'shipper_id', label: 'Seller', useLabel: true, placeholder: 'Seleccione seller', clearable: true, change: this.changeShipper, validation: 'required', useSkeleton: false, disabled: false },
        { fieldType: 'FieldSelect', containerClass: 'col-md-3', name: 'integration', label: 'Integración(mkp)', useLabel: true, clearable: true, change: this.changeIntegration, validation: 'required', dependency: 'shipper_id', useSkeleton: false },
        { fieldType: 'FieldDatepicker', containerClass: 'col-md-3', label: 'Fecha de creación', name: 'creation_date', range: true, clearable: true},
        { fieldType: 'FieldInput', containerClass: 'col-md-3', label: 'OT/Referencia', name: 'tracking-number'}
      ]
      if (['admin'].includes(this.mySession.role)) {
        this.fields.unshift({ fieldType: 'FieldSelect', containerClass: 'col-md-3 mb-50', name: 'organization_id', label: 'eCommerce/Marketplace', useLabel: true, clearable: true, change: this.changeOrganization, validation: 'required', persistSearch: true, searchOnType: { fx: e => this.getOrganizationsFetch(e), nChars: 3, debounce: 600 }, options: []})
        this.fields[this.m_getElementIndex(this.fields, 'name', 'shipper_id')].dependency = 'organization_id'
      } 
      else if (this.mySession.role === 'marketplace') {
        this.organizationId = this.$session.get('cas_user').organization.id
        this.changeOrganization('organization_id', this.$session.get('cas_user').organization)
      } 
      else {
        this.fields.shift()
        this.fields[this.m_getElementIndex(this.fields, 'name', 'integration')].dependency = ''
        this.shipper_id = this.$session.get('cas_user')?.shipper?.id
        this.getMarketcenter(this.shipper_id)
      }
    },
    setFieldsType () {
      this.optionState = [
        {id: 1, html: `Con Manifiesto <span class="badge badge-primary badge-pill">${this.listCount.withSummaries}</span>`, value: true}, 
        {id: 2, html: `Sin Manifiesto <span class="badge badge-warning badge-pill">${this.listCount.noSummaries}</span>`, value: false}
      ]
      this.fieldsType = [  
        {
          fieldType: 'FieldRadio', 
          name: 'activated', 
          containerClass: 'col-sm-12 col-md-6 container-info', 
          align: 'h', 
          options: this.optionState, 
          change: this.changeType
        }
      ]
      this.selectedRadioOption.activated = this.optionState[0]
      this.withSummaries = this.selectedRadioOption.activated.value
    },
    setSchema () {
      this.schema = [
        {label: 'Empresa', key: 'commerce'},
        {label: 'Estado', key: 'status_name', sortable: true, useSlot: true},
        {label: 'Nº envío', key: 'delivery_id', sortable: true, useSlot: true},
        {label: 'Nº manifiesto', key: 'summary.summary_id'},
        {label: 'Orden De Flete', key: 'tracking_number', sortable: true},
        {label: 'Cliente', key: 'client_name', sortable: true, useSlot: true},
        {label: 'Place_level3', key: 'place', sortable: true},
        {label: 'Carrier', key:'carrier_name'},
        {label: 'Fecha de creacion', key: 'created_at', sortable: true}
      ]
      this.customTableByRole()
    },
    setRows () {
      const data = this.setPrinted ? this.printed?.deliveries : []
      const deliveriesSummary = data.filter(item => !!item.summary)
      const deliveriesNotSummary = data.filter(item => !item.summary)
      const filterDeliveries = !!this.withSummaries ? deliveriesSummary : deliveriesNotSummary
      
      this.rows = filterDeliveries.map((item) => {
        return {
          ...item,
          id: Number(item.santiago_identifier),
          status_name: item?.status?.at(-1).name, 
          place: item?.destination?.place?.level4,
          created_at: this.$options.filters.moment(item?.created_at, 'DD-MM-YYYY hh:mm')
        }
      })
      return {deliveriesSummary, deliveriesNotSummary}
    },
    setSelectOptions (name, { options }) {
      const index = this.fields.findIndex(el => el.name === name)
      if (index !== -1) {
        this.fields[index].options = options
        if (!['organization_id'].includes(name)) this.keyFormRender++
      }
    },
    getOrganizationsFetch(name) {
      const data = { 
        name: 'getOrganizations',
        queryParams: {name, simplified: true }
      }
      this.$store.dispatch('fetchService', data)
    },
    getMarketcenter(shipper_id) {
      this.isActiveSkeleton('integration', true)
      const data = {
        name: 'getMarketcenterByShipperId',
        params: {shipper_id},
        onSuccess: () => this.isActiveSkeleton('integration', false)
      }
      this.$store.dispatch('fetchService', data)
    },
    isActiveSkeleton (field, activated) {
      this.fields[this.m_getElementIndex(this.fields, 'name', field)].useSkeleton = activated
    },
    getListPrinted () {
      const shipper_id = this.form['shipper_id']?.id || this.shipper_id
      const service = {
        name: 'getListPrinted',
        params: {shipper_id, limit: this.pagination?.limit, page: this.pagination?.page},
        queryParams: {
          ...this.filters,
          'paginate_by': this.pagination.limit,
          'page': this.pagination.page
        }
      }
      this.$store.dispatch('fetchService', service)
    },
    filterList() {
      this.setPrinted = true
      const filters = {
        'start_date': this.form.creation_date?.start ? this.$options.filters.moment(this.form.creation_date?.start, 'YYYY-MM-DD') : '',
        'end_date': this.form.creation_date?.end ? this.$options.filters.moment(this.form.creation_date?.end, 'YYYY-MM-DD') : '',
        'marketcenter_code': this.form.integration?.id ?? '',
        'reference': this.form['tracking-number'] ?? ''
      }
      Object.keys(filters).map(function(key, value) {
        if (filters[key] === '') delete filters[key]
      })
      this.filters = {
        ...filters
      }
      this.selectedRows = []
      this.loading.first = false
      this.getListPrinted()
    },
    cleanFilter () {
      this.setPrinted = false
      this.form = {}
      this.filters = {}
      this.rows = []
      this.selectedRows = []
      this.optionState[0].html = 'Con Manifiesto <span class="badge badge-primary badge-pill">0</span>'
      this.optionState[1].html = 'Sin Manifiesto <span class="badge badge-warning badge-pill">0</span>'
      if (this.fields[this.m_getElementIndex(this.fields, 'name', 'shipper_id')]) this.fields[this.m_getElementIndex(this.fields, 'name', 'shipper_id')].containerClass = 'col-md-3'
      this.keyFormRender++
    },
    changeType (name, selectedData) {
      this.selectedRows = []
      this.selectedCheck = selectedData.id
      this.withSummaries = selectedData.value
      const summaryIdRow = {label: 'N° de Manifiesto', key: 'summary.summary_id', sortable: true}
      const index = this.schema.findIndex(el => el.key === 'delivery_id') + 1
      if (selectedData.value) this.schema.splice(index, 0, summaryIdRow)
      else this.schema = this.schema.filter(row => row.key !== 'summary.summary_id')
      this.setRows()
    },
    changeOrganization (name, value) {
      this.fields[this.m_getElementIndex(this.fields, 'name', 'shipper_id')].containerClass = 'col-md-3'
      this.form['shipper_id'] = null
      this.form['integration'] = null
      this.keyFormRender++
      if (!!value?.id) {
        const { organization_type } = value
        if (organization_type.id === 1) {
          this.isActiveSkeleton('shipper_id', true)
          const data = {
            name: 'getShippersByOrganizationSimplifiedQueryParams',
            params: {organization_id: this.form['organization_id']?.id || this.organizationId},
            onSuccess: () => this.isActiveSkeleton('shipper_id', false)
          }
          this.$store.dispatch('fetchService', data)
        } 
        else {
          this.form['shipper_id'] = value
          this.fields[this.m_getElementIndex(this.fields, 'name', 'shipper_id')].containerClass = 'hide'
          this.getMarketcenter(value.id)
        }
        this.keyFormRender++
      }
    },
    changeShipper (name, value) {
      if (!!value.id) {
        this.getMarketcenter(value.id)
      } else {
        this.form['integration'] = null
      }
      this.keyFormRender++
    },
    changeIntegration () {
      this.keyFormRender++
    },
    downloadExcel () {
      const payloadExport = this.setPayload() 
      this.loading.excel = true
      this.baseService.callService('exportDeliveriesModulePrinted', payloadExport, null)
        .then(resp => {
          if (resp.data.url) window.open(resp.data.url, '_blank')
          else this.$alert(this.$t(resp.data.message))
        })
        .catch(err => {
          this.$alert(this.$t('Ocurrió un problema al exportar el archivo'))
        })
        .finally(end => {
          this.loading.excel = false
        })
    },
    setPayload () {
      const payload =  {
        carrier_code: 'MKC',
        service_type: this.filters['marketcenter_code'],
        status_in: 26,
        with_summary: this.selectedRadioOption.activated.value,
        limit: this.pagination.limit, 
        page: this.pagination.page
      }
      if (this.filters['start_date']) {
        payload['created_at_from'] = this.filters['start_date']
        payload['created_at_to'] = this.filters['end_date']
      }
      if (this.filters['reference']) {
        payload['delivery_number'] = this.filters['reference']
      }
      
      if (this.mySession.role !== this.ROLES.admin) payload.user = {}
      const roles = {
        admin: 1,
        ecommerce: 2,
        seller: 2,
        pudo: 3,
        marketplace: 4
      }
      if (['marketplace'].includes(this.mySession.role)) {
        payload.user.organization_id = this.mySession.organization.id
        payload.shipper_id = this.form['shipper_id'].id
        payload.user.role_id = roles[this.mySession.role]
      }
      if (['ecommerce', 'seller'].includes(this.mySession.role)) {
        payload.user.shipper_id = this.mySession.shipper.id
        payload.user.role_id = roles[this.mySession.role]
      }
      if (['admin'].includes(this.mySession.role)) {
        payload.shipper_id = this.form['shipper_id'].id
      }

      return payload
    },
    getStatusPrintedId(status) {
      return status?.at(-1)?.id
    },
    customTableByRole () {
      if (['ecommerce', 'seller'].includes(this.mySession.role)) {
        this.schema.shift()
      }
    },
    showLabelsPrintOptions (process) {
      this.serviceType = process
      if (process === 'only-assign') this.$bvModal.show('modalAssignSummary')
      else this.$bvModal.show('modalDeliveryLabels')
    },
    collectLabelsPrintData (data, refreshTable) {
      if (!!refreshTable) this.setTable()
      this.orderedLabels = data
      this.$bvModal.hide('modalDeliveryLabels')
      this.showLabelsPrints()
    },
    setTable () {
      this.filterList()
    },
    showLabelsPrints () {
      this.$bvModal.show('modalDeliveryLabelsPrint')
    },
    openDeliveryDetails (id) {
      return this.$router.resolve({ name: 'deliveries-details', params: { id } }).href
    }
  }
}
</script>
<style>
.table-listprinted .table-render-skeleton {
  overflow-x: hidden !important;
}
.hide {
  display: none;
}
.redirect-link {
    text-decoration: underline !important;
}
</style>